.wrapper {
  position: relative;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.info {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.4;
  color: #616161;
}

.label {
  display: block;
  font-size: 12px;
  color: #616161;
}

.toggleButtons {
  display: flex;
  margin-top: 8px;
  margin-bottom: 14px;

  & > button {
    height: 32px;
    padding: 0;
    font-size: 13px;
    color: #555;
    background-color: white;
    border: 1px solid #DEDEDE;
    cursor: pointer;

    &:first-child {
      width: 40%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      width: 60%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left-color: white;
    }
  }
}

.levelControls {
  display: flex;
  margin-bottom: 8px;
}

.checkboxRoot {
  margin-right: 24px;

  & > span:first-child {
    padding: 4px;
  }
}

.checkboxLabel {
  font-size: 12px;
  color: #616161;
}

.actionButton {
  transition: all 200ms ease-in-out;
}

.descriptionText {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 12px;
}

.disabledButton {
  opacity: .7;
  cursor: default;
  pointer-events: none;
}
