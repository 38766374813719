.wrapper {
  position: absolute;
  top: 60px;
  left: 436px;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: calc(100% - 456px - 382px);
  max-height: calc(100% - 119px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, height .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform: translateX(16px);

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.header {
  position: relative;
  padding: 16px;
}

.title {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px;

  &:hover {
    & svg {
      fill: #8E8E8E;
    }
  }

  & svg {
    font-size: 20px;
    fill: #A2A2A2;
    transform: rotate(180deg);
    transition: fill 200ms ease-in-out, transform .5s ease;
  }
}

.closeButtonCollapse {
  & svg {
    transform: rotate(0deg);
  }
}

.collapseWrapper {
  overflow: scroll;
}

.content {
  position: relative;
  overflow-y: scroll;
  padding: 4px 16px 16px;
}

.dataTableWrapper {
  margin-top: 12px;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #EBEAEF;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tableTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #555;

  & b {
    font-weight: 500;
  }

  & > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
  }
}

.csvDownloadLink {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  background-color: #777;
  border-radius: 4px;
  transition: all 250ms ease-in-out;

  & svg {
    font-size: 20px;
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}
