.title {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  margin: 0;
  color: #555;
  background-color: #F7F7F7;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.navListWrapper {
  overflow-y: scroll;
}

.navList {
  padding: 0;
  margin: 16px;

  & > li {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    & > a {
      display: block;
      color: #444;
      font-size: 14px;
      text-transform: uppercase;
      transition: color 200ms ease-in-out;

      &:hover {
        color: #16a085;
      }
    }

    & > span {
      display: block;
      color: #444;
      font-size: 14px;
      cursor: default;
      text-transform: uppercase;
    }

    & > ul {
      padding-left: 16px;
      margin-top: 8px;

      & > li {
        display: block;
        margin-top: 8px;
        margin-bottom: 8px;

        & > a {
          display: block;
          color: #444;
          font-size: 12px;
          text-transform: uppercase;
          transition: color 200ms ease-in-out;

          &:hover {
            color: #16a085;
          }
        }
      }
    }
  }
}
