.wrapper {
  padding: 16px;
  background-color: white;
  width: 420px;
  position: absolute;
  top: 90px;
  z-index: 4000;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  left: 16px;
  overflow-y: auto;
  border-radius: 6px;
  height: auto;

  @media screen and (max-width: 620px) {
    width: calc(100% - 30px);
    top: 66px;
    transform: translate(0px, 80px);
  }

  @media screen and (min-width: 834px) {

    //top: 66px;
    //transform: translate(0px, 15px);
  }

}

.wrapperWithHeader {
  height: calc(100% - 200px);
  left: 0px;
  transform: translate(410px, 60px);
}

.header {
  position: relative;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
  }
}

.dataList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 16px;
  margin-bottom: 0;
}

.dataListItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  width: 50%;

  &:last-child {
    margin-bottom: 0;
  }
}
.dataListItemFull {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;

  span{
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dataListItemLabel {
  margin-bottom: 4px;
  font-size: 12px;
  color: #777;
}

.dataListItemValue {
  font-size: 12px;
  color: #444;
}

.chartWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}
