.wrapper {
  position: absolute;
  top: 76px;
  left: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, height .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform: translateX(64px);

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    height: calc(100% - 92px);
    transform: translateX(10px);
  }
}

.wrapperWithHeader {
  width: calc(100% - 474px);
  transform: translateX(458px);
}

.header {
  position: relative;
}

.title {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .07em;
  color: #353f4d;

  // & > span:first-child::after {
  //   content: ' - ';
  //   color: #777;
  // }
}

.closeButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
  }
}

.content {
  margin-top: 24px;
  overflow-y: scroll;
}

.chartsWrapper {
  display: flex;
  margin-top: 16px;

  & > div:first-child {
    width: 25%;
  }

  & > div:last-child {
    width: 75%;
  }

  & h3 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}

.dataTableWrapper {
  margin-top: 12px;
  margin-bottom: 16px;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tableTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;

  & b {
    font-weight: 500;
  }
}

.tableWrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.table {
  & th,
  & td {
    font-size: 12px;
  }

  & th {
    font-weight: 700;
    white-space: nowrap;
  }
}

.loadingWrapper {
  margin-top: 32px;
  text-align: center;
}

.smallText {
  margin-bottom: 0;
  font-size: 12px;
}

.alertInfo {
  padding: 12px 24px;
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: .25rem;
}
