.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 16px;
  background-color: #636466;
  padding: 6px 12px 8px;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.footerLogo {
  height: 15px;
  width: 64;
}

.versionText {
  font-size: 12px;
  margin-right: 5px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
}

.copyText {
  font-size: 12px;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
}
