.wrapper {
  padding: 16px;
  background-color: white;
  width: 628px;
  position: absolute;
  top: 76px;
  left: 0;
  z-index: 4000;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transition: width .5s ease, transform .5s ease, opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transform: translateX(64px);

  @media screen and (max-width: 768px) {
    width: calc(100% - 20px);
    height: calc(100% - 92px);
    transform: translateX(10px);
  }
}

.wrapperWithHeader {
  transform: translateX(458px);
}

.header {
  position: relative;
}

.title {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 6px;

  & svg {
    font-size: 18px;
  }
}

.content {
  overflow-y: scroll;

  & p {
    font-size: 14px;
    line-height: 1.4;
  }
}

.contentDescription {
  margin-top: 18px;
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.4;

  &:last-child {
    margin-bottom: 0;
  }
}

.imageList {
  display: flex;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  & > li {
    width: 120px;
    margin: 0 16px;
    text-align: center;

    & > img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-bottom: 8px;
    }

    & > span {
      font-size: 12px;
    }
  }
}
