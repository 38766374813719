.content {
  margin-top: 12px;
  overflow-y: scroll;
}

.radioGroup {
  flex-direction: row;
  justify-content: center;

  & > label {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }
}

.radioLabel {
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.radioGroupInput {
  @media screen and (max-width: 768px) {
    padding: 6px;
  }
}

.tabListWrapper {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 32px;

  & li {
    padding: 10px 32px;
  }
}

.sankeyWrapper {
  width: 100%;
  height: 478px;
}

.sankeyYears {
  margin-top: 0;
  text-align: center;
  font-size: 14px;
}

.loadingWrapper {
  margin-top: 32px;
  text-align: center;
}

.categoryCell {
  white-space: nowrap;
}

.matrixTable {
  & td {
    height: 34px;
    padding: 0 24px 0 16px;
    line-height: 34px;
    background-color: #F1F1F1;
  }

  @media screen and (max-width: 768px) {
    & th, td {
      font-size: 12px;
    }
  }
}

.tableWrapper {
  position: relative;
  display: flex;
  padding-top: 40px;
  padding-left: 40px;
  background-color: #F1F1F1;
  overflow: scroll;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 56px;
    height: 56px;
    border: 2px solid #CCC;
    border-width: 0 2px 0 0;
    transform: rotate(-45deg) translate3d(-27px, -11px, 0);
  }

  @media screen and (max-width: 768px) {
    padding-top: 30px;
    padding-left: 30px;

    &::after {
      width: 42px;
      height: 42px;
      transform: rotate(-45deg) translate3d(-20px, -8px, 0);
    }
  }
}

.tableLeftTag {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40px;
  text-align: center;
  background-color: #DEDEDE;

  & > span {
    position: absolute;
    top: 50%;
    display: block;
    transform: rotate(-90deg) translateX(-25%);
  }

  @media screen and (max-width: 768px) {
    width: 30px;

    & > span {
      font-size: 12px;
    }
  }
}

.tableTopTag {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  background-color: #DEDEDE;

  @media screen and (max-width: 768px) {
    height: 30px;
    line-height: 30px;

    & > span {
      font-size: 12px;
    }
  }
}

.tableCategoriesList {
  padding-top: 37px;
  padding-left: 0;
  margin: 0;

  & > li {
    height: 35px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 34px;
    white-space: nowrap;
    font-size: 0.875rem;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  @media screen and (max-width: 768px) {
    & > li {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 12px;
    }
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tableTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;

  & > b {
    font-weight: 500;
  }

  & > span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
  }
}

.csvDownloadLink {
  display: flex;
  align-items: center;
  padding: 4px 15px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 4px;
  transition: all 250ms ease-in-out;

  & svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
}
